<template>
  <button :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['mode']
};
</script>

<style scoped>
button {
  margin: 0.3rem 0rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: inherit;
  background-color: rgb(194, 209, 29);
  border: 1px solid rgb(194, 209, 29);
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

button:hover,
button:active {
  background-color: gray;
  border-color: gray;
}

.flat {
  background-color: rgb(194, 209, 29);
  color: white;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: rgba(194, 209, 29, 0.2);
  color: rgb(194, 209, 29);
}
</style>
