import { createApp } from 'vue';

import App from './App.vue';
import BaseCard from './ui/BaseCard';
import BaseButton from './ui/BaseButton';
import BaseDialog from './ui/BaseDialog';

import store from './store/index';
import router from './router';

// Set up global app
const app = createApp(App);

app.use(store);
app.use(router);
router.beforeEach((to, from, next) => {
  store.commit('closeSideMenu');
  next();
});

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);

app.mount('#app');
