<template>
  <section>
    <base-card class="container">
      <h3>Sign Up As a New User</h3>
      <form @submit.prevent="signUp">
        <div for="email" class="form-control">
          <label>Email/Username</label>
          <input id="email" name="email" type="text" v-model="enteredEmail" />
        </div>
        <div for="password" class="form-control">
          <label>Password</label>
          <input
            id="password"
            name="password"
            type="password"
            v-model="enteredPassword"
          />
        </div>
        <div for="confirm-password" class="form-control">
          <label>Confirm Password</label>
          <input
            id="confirm-password"
            name="confirm-password"
            type="password"
            v-model="confirmedPassword"
          />
        </div>
        <base-button>Sign Up</base-button>
        <router-link to="/login">Back to login</router-link>
      </form>
      <div class="error" v-if="errorMsg">
        <p>{{ errorMsg }}</p>
      </div>
      <div v-if="successMsg">
        <p>{{ successMsg }}</p>
      </div>
    </base-card>
  </section>
</template>

<script>
import { signUpUser } from '../../api/backend';

export default {
  data() {
    return {
      enteredEmail: '',
      enteredPassword: '',
      confirmedPassword: '',
      errorMsg: null,
      successMsg: null
    };
  },
  methods: {
    async signUp() {
      // Validate password and confirm password are the same
      if (this.enteredPassword != this.confirmedPassword) {
        this.errorMsg = 'Please enter matching passwords';
        this.enteredPassword = '';
        this.confirmedPassword = '';
        return;
      }
      // Do additional validation... email is formatted properly and minimum passord requirements

      // Attempt sign-up with server
      const signUpResponse = await signUpUser(
        this.enteredEmail,
        this.enteredPassword
      );

      if (!signUpResponse.error) {
        this.errorMsg = '';
        this.successMsg = signUpResponse.message;
        this.enteredEmail = '';
        this.enteredPassword = '';
        this.confirmedPassword = '';
      } else {
        this.errorMsg = signUpResponse.message;
        this.successMsg = '';
      }
    }
  }
};
</script>

<style scoped>
.container {
  width: 19rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
h3 {
  margin-bottom: 2rem;
}

.form-control {
  margin: 0.5rem 0;
}

.error {
  color: red;
}
a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
