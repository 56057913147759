<template>
  <base-card class="container">
    <h3>Your UNNIfis</h3>
    <select-device v-if="hasDevices"></select-device>
    <table v-if="hasDevices">
      <thead>
        <th></th>
        <th>Name</th>
        <th>SSID</th>
        <th>Password</th>
      </thead>
      <tr v-for="device in devices" :key="device.imei">
        <td>
          <base-button mode="flat" @click="editDevice(device.imei)">
            Edit
          </base-button>
        </td>
        <td>{{ device.name }}</td>
        <td>{{ device.ssid }}</td>
        <td>{{ device.password }}</td>
      </tr>
    </table>

    <p v-if="!hasDevices">
      No UNNIfis are currently registered with this account.
    </p>

    <base-button @click="addDevice">Add UNNIfi</base-button>
    <router-link to="/user-details">Return to User Details</router-link>
  </base-card>
</template>

<script>
import SelectDevice from '../nav/SelectDevice';

export default {
  components: {
    SelectDevice
  },
  computed: {
    devices() {
      return this.$store.getters.devices;
    },
    hasDevices() {
      return this.$store.getters.hasDevices;
    }
  },
  methods: {
    addDevice() {
      this.$router.push('/add-device');
    },
    editDevice(imei) {
      const path = '/edit-device/' + imei;
      this.$router.push(path);
    }
  }
};
</script>

<style scoped>
.container {
  width: 35rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
table {
  border-spacing: 1rem 0.5rem;
}
th {
  text-align: left;
}

a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}
</style>
