<template>
  <base-card class="container">
    <h3>Confirm Purchase of Data Package</h3>
    <div><b>Device Name:</b> {{ selectedDevice.name }}</div>
    <div><b>SSID:</b> {{ selectedDevice.ssid }}</div>
    <div><b>IMEI:</b> {{ selectedDevice.imei }}</div>
    <br />
    <div><b>Package Name:</b> {{ dataPackage.displayName }}</div>
    <div><b>Price:</b> US${{ dataPackage.priceUSD }}</div>
    <br />
    <base-button
      @click="
        buyPackage(
          dataPackage.displayName,
          dataPackage.priceUSD,
          dataPackage.uclId
        )
      "
      mode="flat"
      >Buy</base-button
    >
    <br />
    <div>
      You will be redirected to a Stripe checkout page to enter your credit card
      details and complete the transaction. If successful, you should be
      returned to this site and see the new data package added to your balance.
      Please send an email to
      <b><a href="mailto:info@unnitel.com">info@unnitel.com</a></b> if there are
      any problems or questions.
    </div>
    <div v-if="successMsg">
      <p>{{ successMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { getStripeSession } from '../../api/backend';

export default {
  data() {
    return {
      successMsg: null,
      packageName: '',
      packagePrice: 0,
      packageUclId: ''
    };
  },
  props: ['packageId'],
  computed: {
    userToken() {
      return this.$store.getters.userToken;
    },
    userEmail() {
      return this.$store.getters.userEmail;
    },
    selectedDevice() {
      return this.$store.getters.selectedDevice;
    },
    dataPackage() {
      const bundles = this.$store.getters.bundleOffers;
      return bundles.find(ele => {
        return ele.uclId === this.packageId;
      });
    }
  },
  methods: {
    async buyPackage(name, price, packageId) {
      const item = {
        name: name,
        description: name,
        amountUSD: price,
        quantity: 1
      };
      // Put into an environment variable
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const stripeSessionResponseData = await getStripeSession(
        item,
        packageId,
        this.selectedDevice.imei
      );
      const stripeSession = stripeSessionResponseData.session;

      stripe.redirectToCheckout({
        sessionId: stripeSession.id
      });
    }
  }
};
</script>

<style scoped>
.container {
  margin: 5rem auto;
  width: 23rem;
  background: white;
  padding: 3rem 2rem;
  display: block;
  border: 1px solid green;
}

.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}

button {
  margin-bottom: 1rem;
}
</style>
