<template>
  <div
    @click="closeSideMenu"
    class="backdrop"
    :class="{ 'display-block': displayBackdrop }"
  ></div>
  <header>
    <div class="logo"><img src="../../assets/unnitel.png" /></div>
    <nav class="main-nav">
      <ul class="main-nav__items">
        <li class="main-nav__item" v-if="isLoggedIn && hasDevices">
          <router-link
            :class="{ 'selected-route': connectionRouteIsActive }"
            to="/connection-status"
            >Connection</router-link
          >
        </li>

        <li class="main-nav__item" v-if="isLoggedIn && hasDevices">
          <router-link to="/data-packages">Data</router-link>
        </li>
        <li class="main-nav__item" v-if="isLoggedIn">
          <router-link to="/user-details">Account</router-link>
        </li>

        <li class="main-nav__item" v-if="isLoggedIn">
          <button @click="logout">Logout</button>
        </li>
      </ul>
    </nav>
    <div v-if="isLoggedIn" @click="openSideMenu" class="toggle-button">
      <button class="toggle-button" @click="toggleSideMenu">
        <span class="toggle-button__bar"></span>
        <span class="toggle-button__bar"></span>
        <span class="toggle-button__bar"></span>
      </button>
    </div>
  </header>

  <nav class="mobile-nav" :class="{ 'display-block': displaySideMenu }">
    <ul class="mobile-nav__items">
      <li class="mobile-nav__item" v-if="isLoggedIn && hasDevices">
        <router-link to="/connection-status">Connection</router-link>
      </li>

      <li class="mobile-nav__item" v-if="isLoggedIn && hasDevices">
        <router-link to="/data-packages">Data</router-link>
      </li>
      <li class="mobile-nav__item" v-if="isLoggedIn">
        <router-link to="/user-details">Account</router-link>
      </li>

      <li class="mobile-nav__item" v-if="isLoggedIn">
        <button @click="logout">Logout</button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    hasDevices() {
      return this.$store.getters.hasDevices;
    },
    displaySideMenu() {
      return this.$store.getters.displaySideMenu;
    },
    displayBackdrop() {
      return this.$store.getters.displayBackdrop;
    },
    connectionRouteIsActive() {
      return true;
    },
    dataRouteIsActive() {
      return false;
    },
    accountRouteIsActive() {
      return true;
    }
  },
  methods: {
    closeSideMenu() {
      this.$store.dispatch('closeSideMenu');
    },
    openSideMenu() {
      this.$store.dispatch('openSideMenu');
    },
    logout() {
      this.$store.dispatch('logout');
      //   this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.router-link-active {
  font-weight: bold;
  color: black !important;
}

.backdrop {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.side-menu {
  display: fixed;
}

header {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.5rem 1.5rem;
  background-image: linear-gradient(180deg, black, #cccccc);
  display: flex;
  justify-content: space-between;
}

.logo img {
  height: 2.5rem;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  /* width: 20%; */
}

.toggle-button {
  display: inline-block;
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__bar {
  width: 100%;
  height: 0.2rem;
  background: rgb(194, 209, 29);
  display: block;
  margin: 0.6rem 0;
}

.mobile-nav {
  display: none;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background: white;
  width: 15rem;
  height: 100vh;
}

.mobile-nav__items {
  list-style: none;
  margin: 1;
  width: 80%;
}

.mobile-nav__item {
  margin: 1;
  background: transparent;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
}

.mobile-nav__item a,
.main-nav__item a {
  text-decoration: none;
  color: rgb(194, 209, 29);
}

.mobile-nav__item a:hover,
.mobile-nav__item a:active {
  color: black;
}

.mobile-nav__item button,
.main-nav__item button {
  margin: 0rem;
  padding: 0.5rem 0.5rem;
  font: inherit;
  background-color: transparent;
  border: 2px solid rgb(194, 209, 29);
  color: black;
  cursor: pointer;
}

.main-nav__item button {
  padding: 0.5rem 1rem;
  color: white;
}

.mobile-nav__item button:hover,
.mobile-nav__item button:active {
  background-color: transparent;
  border-color: black;
  color: black;
}

.main-nav__item button:hover,
.main-nav__item button:active {
  background-color: transparent;
  border-color: white;
  color: white;
}

.main-nav {
  display: none;
}

.main-nav__items {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-nav__item {
  margin: 0 1rem;
}

.display-block {
  display: block;
}

/* Non-mobile */
@media (min-width: 40rem) {
  .toggle-button {
    display: none;
  }
  .main-nav {
    display: flex;
  }
  .router-link-active {
    font-weight: bold;
    color: white !important;
  }
}

.selected-route a {
  font-weight: bold;
  color: white;
}

a:hover {
  color: white;
}
</style>
