<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  margin: 10rem auto;
  background: white;
  padding: 3rem 2rem;
  display: block;
  border: 1px solid green;
}
</style>
