<template>
  <base-card class="container">
    <h3>Change Password</h3>
    <form @submit.prevent="changePassword">
      <div for="password" class="form-control">
        <label>Password</label>
        <input
          id="password"
          name="password"
          type="password"
          v-model="enteredPassword"
        />
      </div>
      <div for="confirm-password" class="form-control">
        <label>Confirm Password</label>
        <input
          id="confirm-password"
          name="confirm-password"
          type="password"
          v-model="confirmedPassword"
        />
      </div>

      <base-button>Change Password</base-button>
      <router-link v-if="!forgotPassword" to="/user-details"
        >Return to User Details</router-link
      >
      <router-link v-if="forgotPassword" to="/login"
        >Return to Login</router-link
      >
    </form>
    <div v-if="successMsg">
      <p>{{ successMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { changePassword } from '../../api/backend';

export default {
  data() {
    return {
      enteredPassword: '',
      confirmedPassword: '',
      successMsg: null
    };
  },
  computed: {
    userToken() {
      return this.$store.getters.userToken;
    },
    userEmail() {
      return this.$store.getters.userEmail;
    },
    forgotPassword() {
      return this.$route.query.token;
    }
  },
  methods: {
    async changePassword() {
      // Validate password and confirm password are the same
      if (this.enteredPassword != this.confirmedPassword) {
        this.successMsg = 'Please enter matching passwords';
        this.enteredPassword = '';
        this.confirmedPassword = '';
        return;
      }
      const changePasswordResponse = await changePassword(
        this.enteredPassword,
        this.$route.query.email,
        this.$route.query.token
      );

      this.successMsg = changePasswordResponse.message;
      this.enteredPassword = '';
      this.confirmedPassword = '';
    }
  }
};
</script>

<style scoped>
a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

.container {
  width: 19rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}

.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
