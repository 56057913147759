import { createRouter, createWebHistory } from 'vue-router';

// Routing
import LoginScreen from './components/Login/LoginScreen';
import SignUp from './components/Login/SignUp';
import UserDetails from './components/Account/UserDetails';
import AddDevice from './components/Account/AddDevice';
import BuyData from './components/Account/BuyData';
import ChangeEmail from './components/Account/ChangeEmail';
import ChangePassword from './components/Account/ChangePassword';
import ConnectionStatus from './components/Account/ConnectionStatus';
import DataPackages from './components/Account/DataPackages';
import EditDevice from './components/Account/EditDevice';
import DeviceList from './components/Account/DeviceList';
import ConfirmEmail from './components/Account/ConfirmEmail';
import ForgotPassword from './components/Account/ForgotPassword';
import ConfirmPurchase from './components/Account/ConfirmPurchase';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: LoginScreen },
    { path: '/sign-up', component: SignUp },
    { path: '/user-details', component: UserDetails },
    { path: '/add-device', component: AddDevice },
    { path: '/buy-data', component: BuyData },
    { path: '/change-email', component: ChangeEmail },
    { path: '/change-password', component: ChangePassword },
    { path: '/connection-status', component: ConnectionStatus },
    { path: '/data-packages', component: DataPackages },
    { path: '/edit-device/:imei', component: EditDevice, props: true },
    { path: '/device-list', component: DeviceList },
    { path: '/confirm-email', component: ConfirmEmail },
    { path: '/forgot-password', component: ForgotPassword },
    {
      path: '/confirm-purchase/:packageId',
      props: true,
      component: ConfirmPurchase
    }
  ]
});

export default router;
