<template>
  <section>
    <base-card class="container">
      <h3>Add UNNIfi to Account</h3>
      <form @submit.prevent="addDevice">
        <div class="form-control">
          <label for="device-name">Name Your Device (optional)</label>
          <input
            id="device-name"
            name="device-name"
            type="text"
            v-model="enteredDeviceName"
          />
        </div>
        <div class="form-control">
          <label for="device-ssid"
            >SSID<br />
            <span style="font-size: 0.75rem"
              >(e.g "WeRocket_XXXXX" or "Unnifi_XXXXX")</span
            ></label
          >
          <input
            id="device-ssid"
            name="device-ssid"
            type="text"
            v-model="enteredSSID"
          />
        </div>
        <div class="form-control">
          <label for="device-password">Device Password</label>
          <input
            id="device-password"
            name="device-password"
            type="text"
            v-model="enteredPassword"
          />
        </div>
        <div class="form-control">
          <base-button>Add UNNIfi</base-button>
        </div>
      </form>
      <div>
        <p v-if="responseMsg">{{ responseMsg }}</p>
      </div>
    </base-card>
  </section>
</template>

<script>
import { addDevice } from '../../api/backend';

export default {
  data() {
    return {
      enteredDeviceName: '',
      enteredSSID: '',
      enteredPassword: '',
      responseMsg: null
    };
  },

  methods: {
    async addDevice() {
      // API to get account details and populate the account details
      const addDeviceResponse = await addDevice(
        this.enteredSSID,
        this.enteredPassword,
        this.enteredDeviceName
      );
      if (!addDeviceResponse.error) {
        this.$store.dispatch('updateDevices', {});
        this.enteredSSID = '';
        this.enteredDeviceName = '';
        this.enteredPassword = '';
        this.$router.push('/device-list');
      }
      this.responseMsg = addDeviceResponse.message;
    }
  }
};
</script>

<style scoped>
.container {
  width: 19rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
