<template>
  <div class="container">
    <h3>Data Packages</h3>
    <select-device></select-device>
    <table>
      <thead>
        <!-- <th>Package</th> -->
        <th>Region</th>
        <th style="text-align: right">Size</th>
        <th style="text-align: right">Remaining</th>
        <th>Status</th>
        <th>Purchased</th>
      </thead>
      <tr v-for="bundle in bundles" :key="bundle.id">
        <!-- <td>{{ bundle.packageName }}</td> -->
        <td>{{ bundle.region }}</td>
        <td style="text-align: right">
          {{ bundle.sizeMBString }}
        </td>
        <td style="text-align: right">
          {{ bundle.remaingMBString }}
        </td>
        <td>{{ bundle.status }}</td>
        <td style="text-align: right">{{ bundle.purchaseDateString }}</td>
      </tr>
    </table>

    <router-link to="/buy-data">Buy More Data</router-link>
  </div>
</template>

<script>
import { getDataBundles } from '../../api/backend';
import SelectDevice from '../nav/SelectDevice';

export default {
  data() {
    return {
      bundles: []
    };
  },
  components: { SelectDevice },
  computed: {
    imei() {
      return this.$store.getters.selectedDeviceImei;
    }
  },
  methods: {
    async loadDataPackages() {
      {
        const dataBundlesResponse = await getDataBundles(this.imei);
        if (!dataBundlesResponse.error) {
          this.bundles = [];
          for (const bundle of dataBundlesResponse.bundles) {
            const newBundle = {
              id: bundle._id,
              packageName: bundle.name,
              region: bundle.region,
              status: bundle.status,
              sizeMBString: bundle.sizeMBString,
              remaingMBString: bundle.remainingMBString,
              purchaseDateString: bundle.purchaseDateString
            };
            this.bundles.unshift(newBundle);
          }
        }
      }
    }
  },
  mounted() {
    this.loadDataPackages();
  },
  watch: {
    imei: function() {
      this.loadDataPackages();
    }
  }
};
</script>

<style scoped>
.container {
  margin: 5rem auto;
  width: 31rem;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}

a {
  /* border: 1px solid rgb(194, 209, 29); */
  padding: 1rem 0rem;
  text-align: center;
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  background: rgb(194, 209, 29);
  color: white;
}

a:hover {
  color: rgb(194, 209, 29);
  background: white;
  border: 1px solid rgb(194, 209, 29);
}

table {
  border-spacing: 1rem 0.5rem;
  /* border-top: 1px dotted black;
  border-bottom: 1px dotted black; */
}
td,
th {
  text-align: left;
  border-bottom: 1px dotted black;
}

.right-align {
  text-align: right;
  padding-right: 1rem;
}
</style>
