<template>
  <base-card class="container">
    <h3>Forgot Password</h3>
    <form @submit.prevent="forgotPassword">
      <div class="form-control">
        <label>Current Email</label>
        <input id="email" name="email" type="text" v-model="enteredEmail" />
      </div>

      <base-button>Reset Password</base-button>
      <router-link to="/login">Return to Login</router-link>
    </form>
    <div v-if="successMsg">
      <p>{{ successMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { forgotPassword } from '../../api/backend';

export default {
  data() {
    return {
      enteredEmail: '',
      successMsg: null
    };
  },
  computed: {
    userToken() {
      return this.$store.getters.userToken;
    },
    userEmail() {
      return this.$store.getters.userEmail;
    }
  },
  methods: {
    async forgotPassword() {
      // Check if new email already exists
      const forgotPasswordRepsonse = await forgotPassword(this.enteredEmail);

      if (!forgotPasswordRepsonse.error) {
        this.successMsg = forgotPasswordRepsonse.message;
      } else {
        this.successMsg = forgotPasswordRepsonse.message;
      }
      this.enteredEmail = '';
    }
  }
};
</script>

<style scoped>
a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

.container {
  width: 19rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
