<template>
  <base-card class="container">
    <h3>Confirming Email</h3>
    <div v-if="successMsg">
      <p>{{ successMsg }}</p>
    </div>
    <router-link to="/login">Back to login</router-link>
  </base-card>
</template>

<script>
import { confirmEmail } from '../../api/backend';

export default {
  data() {
    return {
      successMsg: null
    };
  },
  methods: {
    async confirmEmail() {
      // Check if new email already exists
      const confirmEmailResponse = confirmEmail(this.$route.query.token);

      if (!confirmEmailResponse.error) {
        this.successMsg =
          'Email confirmed. Please log back in with new credentials.';
        this.$store.dispatch('logout');
      } else {
        this.successMsg = 'Problem changing email address';
      }
      this.enteredEmail = '';
    }
  },
  async mounted() {
    this.confirmEmail();
  }
};
</script>

<style scoped>
a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

.container {
  width: 20rem;
}
.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
