<template>
  <base-card class="container">
    <h3>Buy Data Packages</h3>
    <select-device></select-device>
    <table>
      <thead>
        <th>Package</th>
        <th>Region</th>
        <th>Size</th>
        <th>Price<br />(USD)</th>
        <th></th>
      </thead>
      <tr v-for="bundle in bundles" :key="bundle.id">
        <td>{{ bundle.displayName }}</td>
        <td>{{ bundle.region }}</td>
        <td>
          {{ bundle.sizeString }}
        </td>
        <td class="right-align">
          {{ bundle.priceUSD }}
        </td>
        <td>
          <base-button
            @click="
              confirmPurchase(bundle.displayName, bundle.priceUSD, bundle.uclId)
            "
            mode="flat"
            >Buy</base-button
          >
        </td>
      </tr>
    </table>

    <div v-if="successMsg">
      <p>{{ successMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { getPackages } from '../../api/backend';
import SelectDevice from '../nav/SelectDevice';

export default {
  components: {
    SelectDevice
  },
  data() {
    return {
      successMsg: null,
      bundles: []
    };
  },
  computed: {
    userToken() {
      return this.$store.getters.userToken;
    },
    userEmail() {
      return this.$store.getters.userEmail;
    },
    selectedDeviceImei() {
      return this.$store.getters.selectedDeviceImei;
    }
  },
  methods: {
    async loadPackageDetails() {
      const packageResponseData = await getPackages();
      this.bundles = packageResponseData.packages;
    },
    confirmPurchase(name, price, packageId) {
      this.$router.push('/confirm-purchase/' + packageId);
    }
  },
  async mounted() {
    this.loadPackageDetails();
  }
};
</script>

<style scoped>
.container {
  margin: 5rem auto;
  width: 26rem;
  background: white;
  padding: 3rem 2rem;
  display: block;
  border: 1px solid green;
}

.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}

table {
  border-spacing: 0.5rem 0rem;
  /* border-top: 1px dotted black;
  border-bottom: 1px dotted black; */
}
td,
th {
  text-align: left;
  border-bottom: 1px dotted black;
}

.right-align {
  text-align: right;
  padding-right: 1rem;
}
</style>
