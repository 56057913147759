<template>
  <the-navigation></the-navigation>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import TheNavigation from './components/nav/TheNavigation.vue';

export default {
  components: {
    TheNavigation
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Roboto', sans-serif;
}

main {
  min-height: calc(100vh - 3.5rem - 8rem);
  margin-top: 3.5rem;
}

body {
  background-color: rgba(194, 209, 29, 0.2);
}

h3 {
  margin: 0rem 0rem 2rem 0rem;
}

.container {
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
</style>
