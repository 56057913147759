<template>
  <base-card class="container">
    <h3>User Details</h3>
    <form @submit.prevent>
      <div class="form-control">
        <label>Name</label>
        <input id="name" name="name" type="text" v-model="enteredName" />
      </div>
      <div class="form-control">
        <label>Country</label>
        <input
          id="country"
          name="country"
          type="text"
          v-model="enteredCountry"
        />
      </div>
      <div class="form-control">
        <label>Mobile</label>
        <input id="mobile" name="mobile" type="text" v-model="enteredMobile" />
      </div>
      <div class="form-control">
        <label>Notifications </label>
        <input
          id="mobile"
          name="notification"
          type="checkbox"
          v-model="allowNotifications"
        />

        <div>
          <span style="font-size: 0.75rem"
            >(emails sent to alert of remaining data)</span
          >
        </div>
      </div>
      <div class="form-control">
        <label>Registered Email</label>
        <input
          id="email"
          name="email"
          type="text"
          v-model="userEmail"
          disabled
        />
      </div>
      <base-button @click="updateUserDetails">Update Details</base-button>
      <router-link to="/device-list">Manage UNNIfis</router-link>
      <router-link to="/change-password">Change Password</router-link>
      <router-link to="/change-email">Change Registered Email</router-link>
    </form>
    <div v-if="returnMsg">
      <p>{{ returnMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { getUserDetails, updateUserDetails } from '../../api/backend';
export default {
  data() {
    return {
      enteredName: '',
      enteredCountry: '',
      enteredMobile: '',
      allowNotifications: null,
      returnMsg: null
    };
  },
  computed: {
    userToken() {
      return this.$store.getters.userToken;
    },
    userEmail() {
      return this.$store.getters.userEmail;
    }
  },
  methods: {
    async getUserDetails() {
      const responseData = await getUserDetails();
      if (!responseData.error) {
        this.enteredName = responseData.user.name ? responseData.user.name : '';
        this.enteredCountry = responseData.user.country
          ? responseData.user.country
          : '';
        this.enteredMobile = responseData.user.mobile
          ? responseData.user.mobile
          : '';
        this.allowNotifications =
          responseData.user.allowNotifications != null
            ? responseData.user.allowNotifications
            : true;
      } else {
        this.responseMsg = 'Unable to get user details';
      }
    },
    async updateUserDetails() {
      const responseData = await updateUserDetails(
        this.enteredName,
        this.enteredMobile,
        this.enteredCountry,
        this.allowNotifications
      );

      if (!responseData.error) {
        this.returnMsg = 'User details updated';
      } else {
        this.returnMsg = 'Unable to get user details';
      }
    }
  },
  async mounted() {
    await this.getUserDetails();
  }
};
</script>

<style scoped>
.container {
  width: 20rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}

.form-control {
  margin: 0.5rem 0;
}

a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
