<template>
  <base-card class="container">
    <h3>Change Email</h3>
    <form @submit.prevent="changeEmail">
      <div class="form-control">
        <label>Current Email</label>
        <input
          id="email"
          name="email"
          type="text"
          v-model="userEmail"
          disabled
        />
      </div>
      <div class="form-control">
        <label>New Email</label>
        <input
          id="new-email"
          name="new-email"
          type="text"
          v-model="enteredEmail"
        />
      </div>

      <base-button>Update Email Address</base-button>
      <router-link to="/user-details">Return to User Details</router-link>
    </form>
    <div v-if="successMsg">
      <p>{{ successMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { changeEmail } from '../../api/backend';

export default {
  data() {
    return {
      enteredEmail: '',
      successMsg: null
    };
  },
  computed: {
    userToken() {
      return this.$store.getters.userToken;
    },
    userEmail() {
      return this.$store.getters.userEmail;
    }
  },
  methods: {
    async changeEmail() {
      // Validate new email is different from old email
      if (this.enteredEmail == this.userEmail) {
        this.successMsg =
          'Please enter a different email address if you want to change it. ';
        return;
      }

      // Check if new email already exists
      const changeEmailResponse = await changeEmail(this.enteredEmail);
      this.successMsg = changeEmailResponse.message;
      this.enteredEmail = '';
    }
  }
};
</script>

<style scoped>
a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

.container {
  width: 19rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
