<template>
  <base-card class="container">
    <h3>Edit Device</h3>
    <div>
      <div class="form-control">
        <label>Current Name</label>
        <input
          id="name"
          name="name"
          type="text"
          :value="device.name"
          disabled
        />
      </div>
      <div class="form-control">
        <label>New Name</label>
        <input id="name" name="name" type="text" v-model="enteredName" />
      </div>
      <div class="form-control">
        <label>SSID</label>
        <input
          id="ssid"
          name="ssid"
          type="text"
          :value="device.ssid"
          disabled
        />
      </div>
      <div class="form-control">
        <label>Password</label>
        <input
          id="password"
          name="password"
          type="text"
          :value="device.password"
          disabled
        />
      </div>
      <base-button @click="editDeviceDetails">Update Details</base-button>
      <base-button @click="deleteDevice">Delete</base-button>
    </div>
    <div v-if="responseMsg">
      <p>{{ responseMsg }}</p>
    </div>
  </base-card>
</template>

<script>
import { editDevice, deleteDevice } from '../../api/backend';

export default {
  data() {
    return {
      enteredDeviceName: '',
      responseMsg: null
    };
  },
  props: ['imei'],
  computed: {
    device() {
      const devices = this.$store.getters.devices;
      const device = devices.find(d => {
        return d.imei == this.imei;
      });
      return device;
    }
  },
  methods: {
    async editDeviceDetails() {
      const responseData = await editDevice(this.device.ssid, this.enteredName);

      if (!responseData.error) {
        this.$store.dispatch('updateDevices');
        this.$router.back();
      } else {
        this.responseMsg = 'Unable to update device details';
      }
    },
    async deleteDevice() {
      const responseData = await deleteDevice(this.device.ssid);

      if (!responseData.error) {
        this.$store.dispatch('updateDevices', {
          devices: responseData.devices
        });
        this.$router.back();
      } else {
        this.responseMsg = 'Unable to delete device';
      }
    }
  }
};
</script>

<style scoped>
.container {
  width: 19rem;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
.form-control {
  margin: 0.5rem 0;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}

button {
  margin-right: 0.5rem;
}
</style>
