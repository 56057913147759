import { createStore } from 'vuex';

import { getUserDetails, getDevices, getPackages } from '../api/backend';

import router from '../router';

import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      isLoggedIn: false,
      hasDevices: false,
      userToken: null,
      userEmail: null,
      selectedDeviceImei: null,
      user: {
        _id: null,
        email: null,
        name: null,
        country: null,
        mobile: null,
        defaultDevice: null
      },
      devices: [],
      displaySideMenu: false,
      displayBackdrop: false,
      bundleOffers: []
    };
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    userToken(state) {
      return state.userToken;
    },
    userEmail(state) {
      return state.userEmail;
    },
    selectedDeviceImei(state) {
      return state.selectedDeviceImei;
    },
    user(state) {
      return state.user;
    },
    devices(state) {
      return state.devices;
    },
    selectedDevice(state) {
      return state.devices.find(
        device => state.selectedDeviceImei == device.imei
      );
    },
    hasDevices(state) {
      return state.hasDevices;
    },
    displaySideMenu(state) {
      return state.displaySideMenu;
    },
    displayBackdrop(state) {
      return state.displayBackdrop;
    },
    bundleOffers(state) {
      return state.bundleOffers;
    }
  },
  mutations: {
    login(state, payload) {
      state.isLoggedIn = payload.isLoggedIn;
      state.userToken = payload.userToken;
      state.userEmail = payload.userEmail;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userToken = null;
      state.userEmail = null;
      state.user = null;
      state.devices = null;
      state.selectedDeviceImei = null;
    },
    updateUserDetails(state, payload) {
      state.user = payload.user;
    },
    updateDevices(state, payload) {
      state.devices = payload.devices;
      if (state.devices.length > 0) {
        state.hasDevices = true;
      } else {
        state.hasDevices = false;
      }
    },
    setSelectedDeviceImei(state, payload) {
      state.selectedDeviceImei = payload.imei;
    },
    changeUserEmail(state, payload) {
      state.userEmail = payload.email;
    },
    openSideMenu(state) {
      state.displayBackdrop = true;
      state.displaySideMenu = true;
    },
    closeSideMenu(state) {
      state.displayBackdrop = false;
      state.displaySideMenu = false;
    },
    updateBundleOffers(state, payload) {
      state.bundleOffers = payload.bundleOffers;
    }
  },

  actions: {
    async login(context, payload) {
      await context.commit('login', {
        isLoggedIn: true,
        userToken: payload.userToken,
        userEmail: payload.userEmail
      });
      const userResponseData = await getUserDetails();
      await context.commit('updateUserDetails', {
        user: userResponseData.user
      });
      const deviceResponseData = await getDevices();
      const devices = deviceResponseData.devices;
      await context.commit('updateDevices', {
        devices: devices
      });
      const packageResponseData = await getPackages();
      const packages = packageResponseData.packages;
      await context.commit('updateBundleOffers', { bundleOffers: packages });

      if (devices.length == 0) {
        router.push('/user-details');
      } else {
        await context.commit('setSelectedDeviceImei', {
          imei: devices[0].imei
        });
        router.push('/connection-status');
      }
    },
    async updateDevices(context) {
      const deviceResponseData = await getDevices();
      const devices = deviceResponseData.devices;
      await context.commit('updateDevices', {
        devices: devices
      });
    },
    async updateUserDetails(context) {
      const user = await getUserDetails();
      await context.commit('updateUserDetails', {
        user: user
      });
    },
    async updateBundleOffers(context) {
      const bundleOffers = await getPackages();
      await context.commit('updateBundleOffers', {
        bundleOffers: bundleOffers
      });
    },

    logout(context) {
      context.commit('logout');
      router.push('/login');
    },
    async setSelectedDeviceImei(context, payload) {
      await context.commit('setSelectedDeviceImei', {
        imei: payload.imei
      });
    },
    changeUserEmail(context, payload) {
      context.commit('changeUserEmail', {
        email: payload.email
      });
    },
    openSideMenu(context) {
      context.commit('openSideMenu');
    },
    closeSideMenu(context) {
      context.commit('closeSideMenu');
    }
  }
});

export default store;
