<template>
  <h4>Selected UNNIfi:</h4>
  <select
    id="selectedDevice"
    name="selectedDevice"
    v-model="newlySelectedDevice"
  >
    <option
      v-for="device in devices"
      :key="device.imei"
      :value="device.imei"
      :selected="device.imei == selectedDevice"
      >{{ device.name ? device.name : device.ssid }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      newlySelectedDevice: null
    };
  },
  computed: {
    devices() {
      return this.$store.getters.devices;
    },
    selectedDevice() {
      return this.$store.getters.selectedDeviceImei;
    }
  },
  methods: {
    changeSelectedDevice() {
      this.$store.dispatch('setSelectedDeviceImei', {
        imei: this.newlySelectedDevice
      });
    }
  },
  mounted() {
    this.newlySelectedDevice = this.selectedDevice;
  },
  watch: {
    newlySelectedDevice: function() {
      this.changeSelectedDevice();
    }
  }
};
</script>
<style scoped>
h4 {
  margin: 0.5rem 0.5rem 0.5rem 0rem;
  color: rgb(194, 209, 29);
  /* display: inline-block; */
  font-size: 1.2rem;
}
select {
  min-width: 8rem;
  padding: 0.3rem;
  font-size: 1rem;
}

button {
  margin: 0.3rem 2rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: inherit;
  background-color: rgb(194, 209, 29);
  border: 1px solid rgb(194, 209, 29);
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

button:hover,
button:active {
  background-color: gray;
  border-color: gray;
}
</style>
