<template>
  <base-card class="container">
    <h3>UNNIfi Connection</h3>
    <select-device></select-device>
    <div v-if="connection.status == 'Connected'">
      <h4 class="connected">
        UNNIfi Is Connected
      </h4>
      <h4>Signal Quality: {{ connection.signal }}</h4>
      <div class="leftright top-border">
        <div><b>Battery:</b></div>
        <div>{{ connection.power }}</div>
      </div>
      <div class="leftright top-border">
        <div><b>Data Consumed:</b></div>
        <div>{{ connection.consumed }}</div>
      </div>
      <div class="leftright top-border">
        <div><b>Time Connected:</b></div>
        <div>{{ connection.time }}</div>
      </div>
      <div class="leftright top-border">
        <div><b>Devices Logged On:</b></div>
        <div>{{ connection.numDevices }}</div>
      </div>
      <div class="leftright top-border bottom-border">
        <div><b>Country:</b></div>
        <div>{{ connection.country }}</div>
      </div>
    </div>
    <div v-if="connection.status == 'Disconnected'" class="disconnected">
      <h4>UNNIfi is Disconnected</h4>
    </div>
  </base-card>
</template>

<script>
import { getConnectionStatus } from '../../api/backend';
import SelectDevice from '../nav/SelectDevice';

export default {
  components: {
    SelectDevice
  },
  data() {
    return {
      connection: {
        status: '',
        power: '',
        consumed: '',
        time: '',
        country: '',
        numDevices: '',
        signal: ''
      }
    };
  },
  computed: {
    imei() {
      return this.$store.getters.selectedDeviceImei;
    }
  },
  methods: {
    async loadConnectionStatus() {
      // API to get connection details and populate for disply
      const connectionStatus = await getConnectionStatus(this.imei);

      if (!connectionStatus.error) {
        this.connection.status = connectionStatus.isConnected
          ? 'Connected'
          : 'Disconnected';
        this.detailConnected = connectionStatus.isConnected;
        this.connection.power = connectionStatus.powerLeft
          ? connectionStatus.powerLeft + '%'
          : 'N/A';
        this.connection.consumed = connectionStatus.dataFlowString;
        this.connection.time = connectionStatus.connectionTimeString;
        this.connection.country = connectionStatus.countryName;
        this.connection.numDevices = connectionStatus.connectedDevices
          ? connectionStatus.connectedDevices
          : '';
        this.connection.signal = connectionStatus.signalRatingString;
      }
    }
  },
  mounted() {
    this.loadConnectionStatus();
  },
  watch: {
    imei: function() {
      this.loadConnectionStatus();
    }
  }
};
</script>

<style scoped>
.leftright {
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: space-between;
}
.top-border {
  border-top: 1px dotted gray;
}
.bottom-border {
  border-bottom: 1px dotted gray;
}
.connected {
  color: green;
}
.disconnected {
  color: red;
}
.container {
  margin: 5rem auto;
  width: 20rem;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}
</style>
